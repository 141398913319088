.factories
  &--dropdown
    &--accordion
      margin-bottom: 0px

.header
  width: 100%
  background-color: $header-bg-color
  display: inline-block
  //border-bottom: 2px solid $brand-tertiary
  position: absolute
  top: 0
  height: 56px
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3)

  &__side
    width: 249px !important
    display: initial
    transition: width 450ms ease-in-out !important
    margin-top: 57px
    overflow: hidden !important
    background: linear-gradient(180deg, rgba(0, 68, 133, 0.15) 0%, rgba(0, 68, 133, 0.72) 100%)
    background-position: center

    &-wrapper:hover > div.header__action-drawer
      position: absolute
      left: 249px
      bottom: 24px
      z-index: 1400
      transition: left 450ms ease-in-out, opacity 150ms linear
      opacity: 1

    &-wrapper:hover > div.header__action-drawer--collapse
      position: absolute
      left: 44px
      bottom: 24px
      z-index: 1400
      transition: left 450ms ease-in-out, opacity 150ms linear
      opacity: 1

    &--collapse
      width: 96px !important
      margin-top: 57px
      display: initial
      overflow: hidden !important
      transition: width 450ms ease-in-out !important
      background: linear-gradient(180deg, rgba(0, 68, 133, 0.15) 0%, rgba(0, 68, 133, 0.72) 100%)
      background-position: center

  &__subhead
    background-color: $subheader-bg-color
    padding: 20px 20px 65px 20px
    margin: -20px -20px -48px -20px

    &--users
      padding-bottom: 65px

  &__subhead-text
    font-size: 30px
    padding-bottom: 16px
    &--tablet
      font-size: 24px

  &__menu-items
    display: flex
    float: left
    align-items: center
    height: 100%
    margin-left: 120px

  &__menu-item
    color: $white-color !important
    font-weight: lighter !important
    -webkit-transform: translateY(0) !important
    -ms-transform: translateY(0)!important
    transform: translateY(0) !important
    transition: transform 450ms ease-in-out !important
    margin-top: 2px !important
    margin-left: 2px !important
    margin-right: 8px !important
    border-radius: 0 12px 12px 0 !important
    height: 36px
    min-height: 36px !important
    line-height: 36px !important

    &:hover
      color: #0A71CE !important
      background: #fff !important

    &--active
      @extend .header__menu-item
      color: #0A71CE !important
      background: #fff !important

  &__menu-icon
    color: inherit !important
    background: transparent !important
    -webkit-transform: translateY(0) !important
    -ms-transform: translateY(0)!important
    transform: translateY(0) !important
    transition: transform 450ms ease-in-out !important
    margin: 6px 12px !important

    &--active
      @extend .header__menu-icon
      color: inherit !important
      background: transparent !important

  &--dropdown
    margin-right: 25px

  &__menu-item__top
    text-transform: uppercase
    cursor: pointer
    text-decoration: none
    font-family: $body-font
    margin-right: 25px
    color: $black-color
    font-size: 15px
    position: absolute


    &--active
      @extend .header__menu-item__top
      color: $black-color
      border-bottom: 2px solid $brand-tertiary
      padding-bottom: 3px

  &__menu-item__sub
    color: #fff !important
    font-weight: bold !important
    font-size: 14px !important
    padding-left: 68px !important
    margin: 2px 8px 8px 2px !important
    border-radius: 0 12px 12px 0 !important
    min-height: 36px !important
    line-height: 36px !important

    &:hover
      color: #0A71CE !important
      background: #fff !important

    &--active
      @extend .header__menu-item__sub
      color: #0A71CE !important
      background: #fff !important

    &__sub
      font-weight: bold !important
      color: #fff !important
      font-size: 14px !important
      padding-left: 88px !important
      margin-top: 2px !important
      margin-left: 2px !important
      margin-right: 8px !important
      border-radius: 0 12px 12px 0 !important
      min-height: 36px !important
      line-height: 36px !important

      &:hover
        color: #0A71CE !important
        background: #fff !important

      &--active
        @extend .header__menu-item__sub__sub
        color: #0A71CE !important
        background: #fff !important


  &__text
    float: left
    margin: 12px 16px 8px

    &-logo
      img
        width: 148px

    &-logo--collapse
      img
        margin-left: 16px
        margin-top: 16px
        margin-bottom: 16px
        width: 24px

    &-title
      color: $white-color
      margin: 20px 0px
      font-size: 24px
      font-family: $body-font

  &__action-user
      position: absolute
      right: 100px
      top: 56px
      z-index: 20

  &__action
      position: absolute
      right: 50px
      top: 175px
      z-index: 20

      &-button
        background-color: $brand-secondary
        color: $white-color
        width: 60px
        height: 60px
        border-radius: 50px
        box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.3)
        cursor: pointer
        text-align: center
        padding-top: 16px
        box-sizing: border-box


        span
          color: $white-color !important
          font-size: 30px !important

        &:hover
          background-color: $white-color

          span
            color: $brand-secondary !important


      &-button-text
        position: absolute
        width: 100%
        text-align: center

  &--help-icon
    margin-top: -18px !important



  &__action-drawer
    position: absolute
    left: 249px
    opacity: 0
    bottom: 24px
    z-index: 1400
    transition: left 450ms ease-in-out, opacity 150ms linear

    &:hover
      opacity: 1

    &--collapse
      @extend .header__action-drawer
      opacity: 1
      left: 44px

    &-button
      background-color: $white-color
      color: $brand-primary
      width: 24px
      height: 24px
      border-radius: 50%
      box-shadow: -3px 1px 8px rgba(0, 0, 0, 0.3)
      cursor: pointer
      text-align: center
      padding-top: 4px
      box-sizing: border-box


      span
        color: $brand-primary !important
        font-size: 16px !important

      &:hover
        background-color: $brand-primary

        span
          color: $white-color !important


    &-button-text
      position: absolute
      width: 100%
      text-align: center


.drawer__item
  a
    text-decoration: none !important
    color: $brand-primary !important

    &:hover
      color: $black-color !important

.no_underline
  text-decoration: none
  color: #000
  display: flex

.recharts-text
  font-size: 12px !important
  color: #666


.footer
  position: fixed
  bottom: 0
  width: 100%

  &__help
    font-family: $body-font
    font-weight: bold
    width: 100%
    display: flex
    justify-content: center
    font-size: 12px
    margin-top: 4px
    margin-bottom: 4px

    &--anchor
      margin-left: 4px
      margin-right: 4px
      text-decoration: none
      color: #000


.container-footer-box
  display: flex
  flex-direction: column
  justify-content: space-between

#ga-nav-collapse
  background-color: transparent
  color: #ffffff
  line-height: 48px
  display: flex
  justify-content: flex-end
  padding-right: 12px
  cursor: pointer

.expand-collapse-icon
  margin-left: 85%

  &--collapse
    margin-left: 16px

.expand-sub-menu
  overflow: hidden
  max-height: 500px
  transition: max-height 300ms ease-in-out 100ms


  &--collapse
    max-height: 0
    overflow: hidden
    transition: max-height 300ms ease-in-out

.menu_item
  -webkit-transform: translateY(0)
  -ms-transform: translateY(0)
  transform: translateY(0)
  transition: all 300ms cubic-bezier(0.4, 0, 1, 1)
  margin: 8px 0