.reports
  @extend %card-base
  margin-bottom: 30px
  display: inline-block

  &--collapse
    @extend %card-base--collapse
    margin-bottom: 30px
    display: inline-block

  &__container
    margin-top: 50px

    &-daily
      margin: 0 -20px

  &__title-wrapper
    display: inline-block
    width: 100%

  &__title, &__title-error
    font-size: 24px
    margin-bottom: 15px

    &-sub-wrapper
      float: left
      width: calc(35%)

    &-daily
      margin: -20px -20px 0
      display: flex
      border-bottom: 1px solid #0000001f
      align-items: center

      &__typography
        color: #666
        margin: 30px !important

      &__divider
        margin: 30px 0 !important
        height: 30px !important

  &__sub-title
    font-size: 18px
    clear: both
    padding-top: 15px
    font-family: inherit

  &__efficiency-title
    font-size: 30px
    margin-bottom: 15px
    float: left

  &__efficiency-ytd
    font-size: 30px
    margin-bottom: 15px
    float: right

  &__no-runs

  &__loading

  &__toggle
    display: flex
    flex-flow: row
    justify-content: flex-end
    margin-bottom: 30px

    &--inner
      max-width: 250px
      padding-right: 20px

  &__options
    width: 100%
    display: inline-block
    margin: -20px
    width: calc(100% + 40px)
    padding: 20px
    box-sizing: border-box
    background-color: $options-bg-color
    -webkit-print-color-adjust: exact

    &-item
      width: 200px
      float: left
      font-size: 16px !important
      margin-right: 10px !important
      margin-top: 12px !important
      text-align: start !important
      text-transform: capitalize !important

      &--products
        @extend .reports__options-item
        width: 450px

      &--tablet
        width: 150px
        float: left
        margin-right: 10px
        margin-top: -17px

      &--button
        width: 164px

    &-item_picker
      width: 120px !important
      float: left
      margin-top: 12px !important
      margin-right: 10px !important

    &-print
      cursor: pointer
      float: right
      color: $print-icon-color

      &:hover
        color: $brand-primary
        cursor: pointer

  &__edit-item_picker
    width: 100px !important
    float: left
    margin-right: 10px

  &__floated
    width: 100%
    display: flex //TODO fix flex issue
    &:after
      clear: both

    &--tablet
      width: 100%
      display: flex
      height: 270px
      &:after
        clear: both

  &__data
    //display: inline-block
    //margin-top: 40px
    width: 100%

  &__data--detail
    display: inline-block
    margin-top: 20px

  &__data-efficiency--tablet
    float: left
    width: 120px
    margin-right: 10px

  &__data-efficiency, &__data-efficiency--weekly, &__data-efficiency--weekly--tablet
    float: left
    width: calc(15% - 20px)
    margin-right: 30px

    &-label
      width: 100%
      text-align: center
      font-size: 20px
      margin-bottom: 15px

      &--tablet
        width: 100%
        text-align: center
        font-size: 12px
        margin-bottom: 15px


    &-percent
      text-align: center
      font-size: 40px
      width: 250px
      font-weight: bold
      margin: -10px 0px 40px 0px

      &--tablet
        font-size: 12px
        width: 120px
        text-align: center
        font-weight: bold
        margin: -10px 0px 40px 0px

  &__data-efficiency--weekly
    width: 100%
    float: none
    display: inline-block
    margin-right: none

  &__data-lost-time--tablet
    width: calc(35%)
    float: left
    margin-bottom: 20px
    text-align: center

  &__data-lost-time, &__data-lost-time--weekly
    float: left
    width: calc(85% - 20px)
    margin-bottom: 20px

    &__label-y
      width: 300px
      text-align: center
      font-family: $body-font
      font-size: 16px
      height: 32px
      margin-right: 15px
      float: left
      font-weight: bold
      transform: rotate(-90deg)
      transform-origin: left top 0
      margin-left: 10px
      z-index: 2


      &--tablet
        font-size: 10px
        width: 300px
        text-align: center
        font-family: $body-font
        height: 32px
        margin-bottom: 10px
        float: left
        font-weight: bold
        transform: rotate(-90deg)
        transform-origin: left top 0
        margin-top: 10px

    .recharts-text
      font-size: 12px !important
      color: #000
      font-family: $body-font, Arial, Helvetica, sans-serif

    &-label
      width: 100%
      text-align: center
      font-size: 20px
      margin-bottom: 15px

      &-sub
        width: 100%
        text-align: center
        font-size: 16px
        margin-bottom: 15px

      &-tablet
        font-size: 12px
        text-align: center
        margin-bottom: 15px
        width: 100%

    svg
      overflow: visible !important

  &__data-lost-time--weekly
    width: 100%
    float: none
    display: inline-block

    &__label-y
      width: 300px

  &__data-table-info
    float: left
    width: 100%
    margin-bottom: 30px

    &_weight
      background-color: #F2F2F2
      display: inline-block
      width: calc(100% + 40px)
      margin: 0 0 -23px -20px
      padding: 24px
      box-sizing: border-box

      th
        vertical-align: middle


    &-label
      width: calc(100% - 100px)
      font-size: 20px
      margin-bottom: 15px
      &--tablet
        width: calc(100% - 100px)
        font-size: 18px
        margin-bottom: 15px

  &__multi-select-checkbox
    padding: 8px 16px

    &:hover
      background-color: #e1e1e1

.pareto-title-container
  position: relative

  &--title
    width: 100%
    text-align: center
    font-size: 20px
    margin-bottom: 15px

  &--text-field
    width: 80px !important
    position: absolute !important
    right: 52px
    top: 0
    margin-top: -28px

.run-log
  margin-bottom: 15px
  display: flex
  justify-content: space-between

  &__title
    width: calc(100% - 100px)
    font-size: 20px

.batch-edit, .weight-edit
  display: inline-block
  width: 100%

  &__title
    font-family: $body-font
    font-weight: 600

  &__add-button
    margin-top: 27px

  &__field
    width: calc(21% - 1px)
    float: left

    select
      width: calc(100% - 10px)
      margin-top: 40px

  &__buttons
    width: 37%
    float: left
    margin-top: 28px
    padding-left: 3px
    font-family: $body-font
  
  &__error
    color: $line-error
    margin-top: 5px
    font-size: 12px
    @extend %default-label

  &__error2
    color: $line-error
    margin-top: 12px
    font-size: 13px
    @extend %default-label

.add-closed-run

  &__field
    width: 200px !important
    float: left
    margin-top: -17px
    margin-right: 7px



.rd3-barchart-xaxis
  .tick
    writing-mode: vertical-lr !important

    text
      text-anchor: initial !important
      font-size: 10px !important

  
.tooltip
  background-color: $white-color
  color: $black-color
  padding: 10px
  box-shadow: 0px 3px 8px rgba(0,0,0,0.5)

  &__transparent
    @extend .tooltip
    background-color: rgba(255, 255, 255, 0.90)

  &__title
    font-size: 14px
    font-weight: bold
    margin-bottom: 8px

  &__reason
    font-size: 14px
    font-weight: bold
    margin-bottom: 5px

  &__time-lost
    font-size: 12px
    font-weight: regular
    font-style: italic

  &__click-for-details
    font-size: 12px
    margin-top: 4px



.report__row
  cursor: pointer
  &:hover
    background-color: $report-hover-color !important

.reports__wrapper
  margin-top: 20px
  &--tablet
    margin-top: 2px

.reports__sub
  background-color: $body-bg-color
  display: inline-block
  width: calc(100% + 40px)
  margin: 0px 0px -23px -20px
  padding: 24px
  box-sizing: border-box
  -webkit-print-color-adjust: exact

  &--tablet
    background-color: $body-bg-color
    display: inline-block
    width: calc(100% + 40px)
    margin: 0px 0px -23px -20px
    padding: 5px 24px
    box-sizing: border-box


.report-picking
  &__label-y
    width: 300px
    text-align: center
    font-family: $body-font
    font-size: 16px
    height: 32px
    margin-right: 10px
    float: left
    font-weight: bold
    transform: rotate(-90deg)
    transform-origin: left top 0
    margin-left: 10px

  &-chart
    margin-left: 40px
    width: calc(100% - 40px)

  
.reports__output
  width: 50%
  float: left

.reports__output .recharts-responsive-container
  height: 350px !important
  

.batch-buttons__wrapper
  width: 100%
  text-align: right
  margin-top: 15px

.edit-run-buttons__wrapper
  width: 100%
  text-align: right
  margin-top: 25px
  clear: both

.add-run-progress__wrapper
  width: 100%
  text-align: center
  margin-top: 25px
  font-family: $body-font
  font-size: 18px

.delete-ingredient__text
  float: left
  font-family: $body-font
  font-size: 15px
  line-height: 1.2em
  margin-bottom: 20px

.recharts-surface
  overflow: visible

.reports__data-lost-time--weekly .recharts-responsive-container
  height: 350px !important

.reports__data-efficiency--weekly .recharts-responsive-container
  height: 350px !important

.yield-loss .recharts-responsive-container
  height: 390px !important

.back_to_line
  clear: both
  display: flex
  flex-flow: row
  width: 100%
  justify-content: space-between

.daily-report-tab
  margin-top: 15px
  margin-left: 20px
  margin-right: 20px

.tabs-card
  padding-top: 0
  padding-right: 0
  padding-left: 0
  min-height: 500px
  position: relative

.tabs
  > div:nth-child(2)
    background: rgba(41, 103, 193, 0.12)


.pagination
  display: flex
  flex-direction: row
  justify-content: space-around
  align-items: center
  width: 600px
  margin-top: 16px

.page
  width: 24px
  padding: 8px
  color: #ffffff
  background: $brand-tertiary
  border-radius: 2px
  &:hover
    background: #bfbfbf

  &-previous, &-next
    cursor: pointer
    &:focus
      outline: 0

  &-link
    display: flex
    justify-content: center
    cursor: pointer
    &:focus
      outline: 0

.active
  background: #bfbfbf

.disabled
  color: #bfbfbf
  cursor: not-allowed

  &-text
    opacity: 0.3

.select-products
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0

  &-container
    width: 928px
    max-height: 490px
    position: absolute
    top: 188px
    left: 300px
    background: #fff
    z-index: 100
    border-radius: 8px
    box-shadow: -3px 1px 12px 0px rgba(0, 0, 0, 0.3)

  &-header
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    height: 36px
    width: 100%
    padding-top: 24px
    padding-bottom: 8px

    &-button
      margin-right: 24px !important

  &-body
    display: flex
    flex-wrap: wrap
    flex-direction: row
    height: 408px
    width: 100%
    overflow-y: scroll

    &-item
      width: calc(50% - 24px) !important
      height: 48px
      margin-right: 12px !important
      margin-left: 12px !important
      margin-bottom: 8px
      padding: 8px 0

      &:hover
        background-color: #e1e1e1

    &-line
      width: calc(100% - 24px) !important
      margin-right: 12px
      margin-left: 12px
      margin-bottom: 8px
      padding: 8px 0

      &:hover
        background-color: #e1e1e1

.select-lines
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0

  &-container
    width: 412px
    max-height: 490px
    position: absolute
    top: 188px
    left: 36px
    background: #fff
    z-index: 100
    border-radius: 8px
    box-shadow: -3px 1px 12px 0px rgba(0, 0, 0, 0.3)

  &-header
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: center
    height: 36px
    width: 100%
    padding-top: 24px
    padding-bottom: 8px

    &-button
      margin-right: 24px !important

  &-body
    display: flex
    flex-direction: column
    max-height: 408px
    width: 100%
    overflow-y: scroll

    &-item
      width: calc(100% - 24px) !important
      height: 48px
      margin-right: 12px !important
      margin-left: 12px !important
      margin-bottom: 8px
      padding: 8px 0

      &:hover
        background-color: #e1e1e1


.filter-button

  &-hr
    border-top: none rgb(224, 224, 224)
    border-left: none rgb(224, 224, 224)
    border-right: none rgb(224, 224, 224)
    border-bottom: 1px solid rgb(224, 224, 224)
    bottom: 8px
    box-sizing: content-box
    margin: 0
    width: 100%


.confirm-run-changes
  max-height: 960px
  overflow-y: auto

  &-section
    width: 100%
    display: flex
    flex-direction: column
    justify-content: start
    align-items: flex-start

  &-row
    width: 770px
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: flex-start
    margin-bottom: 8px
    padding: 4px

    &:hover
      background: #e1e1e1

    &--cell
      width: 200px

    &--title-cell
      width: 120px

    &-arrow-cell
      width: 50px

  &-row-title
    @extend .confirm-run-changes-row

    &:hover
      background: #ffffff


.split_timeline

  &_wrapper
    position: relative
    width: 100%
    height: 100%
    padding: 40px 0 50px

  &_container
    position: relative
    height: 100%
    margin: 0 auto
    width: 640px

  &_area
    width: 100%
    height: 40px
    background: rgba(225,226,228,.52)

  &_durations
    position: absolute
    bottom: 100%
    left: 0
    width: 100%
    height: 16px
    margin: 0 0 14px

    &_duration
      position: absolute
      display: flex
      align-items: center
      justify-content: center
      font-size: 12px

      &_number
        flex-shrink: 0
        margin: 0 0 0 3px
        color: #303030

      &_time
        flex-shrink: 0
        padding: 0 5px
        margin: 0 0 0 5px
        white-space: nowrap
        background: rgba(48,48,48,.5)
        border-radius: 10px
        color: #fff

    &_point
      position: absolute
      z-index: 2
      top: -14px
      display: flex
      justify-content: center
      width: 30px
      height: 14px
      font-size: 12px
      text-align: center
      color: #b7b7b7

    &_marker
      position: absolute
      z-index: 2
      bottom: 0
      left: 0
      width: 6px
      height: 40px
      cursor: grab
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      -webkit-user-drag: none

      & > .is-active
        z-index: 3
        cursor: grab

      & > .is-active > .split_timeline_durations_marker_mark_arrow
        fill: #2ba576

      & > .is-active > .split_timeline_durations_marker_mark
        background: #5c5a5a

      &_container
        position: relative
        height: 100%

      &_mark
        position: absolute
        top: 0
        left: 0
        width: 6px
        height: 100%
        margin: 0 0 0 -2px
        opacity: .5
        background: #5c5a5a

        &_arrow
          position: absolute
          top: -10px
          left: -5px
          fill: #c2c7cd

      &_time
        position: absolute
        top: 100%
        left: -4px
        width: 50px
        margin: 6px 0 0 -25px
        color: #7c7c7c
        font-size: 12px
        text-align: center

    &_gradient
      position: absolute
      z-index: 2
      bottom: 0
      height: 100%
      background: #21b27a
      opacity: .22

    &_previous_area, &_next_area
      position: absolute
      z-index: 2
      bottom: 0
      height: 100%
      cursor: pointer


.start
  left: -16px

  &:after
    content: ""
    position: absolute
    left: 14px
    top: 14px
    width: 6px
    height: 40px
    margin: 0 0 6px
    background: #35bf8a

.end
  right: -18px
  margin: 0 0 0 -2px

  &:after
    content: ""
    position: absolute
    right: 14px
    top: 14px
    width: 6px
    height: 40px
    margin: 0 0 6px
    background: #f04735

.follower
  width: 10px

.hidden
  display: none

.is-active


.draggable-dragging
  z-index: 3
  cursor: grabbing

.table-row

  &:hover
    background: #e6e6e6 !important

.chart-year-axis
  margin-left: 8px
  color: rgb(99, 99, 99)
  border-left: solid 2px rgb(99, 99, 99)
  height: 24px
  font-size: 12px
  padding-left: 4px

@media print
  @page
    size: portrait


.chart-label
  font-family: $body-font, Arial, Helvetica, sans-serif !important

.trend

  &__cache-rebuild-message

    &--container
      height: 60px
      margin-top: 4px

    &--text
      font-size: 14px
      margin-bottom: 4px


.no-data > text > tspan
  font-size: 14px !important
  text-anchor: middle !important
  font-family: Arial, sans-serif


.dropdown-button > .MuiButton-label
  justify-content: start

.reports_card
  margin: 0 20px 40px 20px
  box-sizing: border-box
  background: #fff
  box-shadow: 0 0 8px rgba(0,0,0,0.12)
  display: flex
  flex-direction: column

  &--title
    margin: 20px 30px !important

.reports_event_table_head_cell
  font-size: 14px
  color: rgba(0, 0, 0, 1) !important
  width: 16.6%

.reports_event_table_body_cell
  font-size: 16px
  color: rgba(0, 0, 0, 0.87) !important
  width: 16.6%

.reports_speed-chart--responsive-container
  margin-bottom: 60px

.add-event-picker-wrapper
  margin-bottom: 8px
  display: flex

.add-event-picker
  width: 160px !important
  margin-top: 12px !important
  margin-right: 10px !important