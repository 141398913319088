@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap')
$body-font: "Roboto", Arial, Helvetica, sans-serif

$white-color: #ffffff
$black-color: #2B2E34

$brand-primary: #0086FF
$brand-secondary: #E1004B
$brand-tertiary: #2967c1

$card-color: $white-color
$body-bg-color: #F2F2F2
$header-bg-color: $white-color
$modal-bg-color: rgba(0,0,0,0.1)
$modal-content-color: $white-color
$options-bg-color: #e9e9e9
$options-bg-color-selected: #e1e1e1
$subheader-bg-color: #e9e9e9
$report-hover-color: #cccccc

$line-error: #E00000
$line-warning: #FEC600
$line-online: #26C109
$line-offline: #999999
$line-view-options-bg: #EFEFEF
$line-view-options-bg-selected: #666666
$line-view-separator: #c7c7c7
$line-not-running: #a1a1a1

$presentation-mode-data-bg: #000000
$presentation-mode-data-bg-offline: #000000

$liveview-modal-bg: #f2f2f2

$line-item-bg-color: #F2F2F2

$print-icon-color: #666666


%default-label
  color: $brand-primary
  font-size: 11px
  font-family: $body-font

%default-title
  font-family: $body-font
  font-size: 24px