.account
  @extend %card-base

  &--collapse
    @extend %card-base--collapse

  &_label
    width: 100%
    text-align: left
    font-size: 20px
    margin-top: 15px

  &_text_field
    margin-top: 8px !important
    margin-bottom: 8px !important
    width: 360px

  &_timezone_text_field
    margin-top: 16px !important
    margin-bottom: 8px !important
    width: 380px

  &_dropdown
    max-height: 316px !important

  &_email-label
    color: #666
    font-size: 0.75rem

  &_email-div
    padding: 7px 0


  &_details-div
    margin-bottom: 8px
    display: flex
    flex-direction: column

  &_notification-delivery-div
    display: flex
    flex-direction: column

  &_notification-schedule-div
    margin-top: 8px
    display: flex
    flex-direction: column