.organizations
  height: calc(100vh - 70px)
  overflow: auto
  margin-top: 70px
  display: flex
  flex-direction: column

  &_container
    width: calc(100% - 40px)
    margin: 20px

  &_subtitle
    margin: 20px 40px !important

  &_grid-container
    padding: 20px 40px !important

  &_factories-grid
    //border-left: solid 1px #e9e9e9
    padding: 20px 40px !important


  &_item
    width: calc(25% - 10px)
    height: 168px
    margin-right: 10px
    margin-bottom: 20px
    display: flex
    justify-content: center
    align-items: center
    font-size: 36px
    float: left
    background-color: $line-item-bg-color
    box-shadow: -3px 3px 15px rgba(0,0,0,0.3)
    border-radius: 4px
    overflow: hidden
    cursor: pointer
    color: #000
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out

    &:hover
      background-color: $brand-primary
      color: #ffffff

    &--selected
      @extend .organizations_item
      background-color: $brand-primary
      color: #ffffff