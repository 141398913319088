.checkbox-list-container {
    animation: transformation-checkbox-list 0.8s 1 linear;
    animation-fill-mode: forwards;
}
@keyframes transformation-checkbox-list {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
