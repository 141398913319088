.faq

  &-container
    width: 360px
    height: 560px
    z-index: 100000
    top: 72px
    position: absolute
    background-color: #fff
    border: none
    min-width: 360px
    font-family: Roboto
    box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.188235)

  &-header
    background-color: $brand-primary
    cursor: move
    padding: 0 8px
    position: relative
    z-index: 1
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.258824), 0 2px 10px 0 rgba(0,0,0,0.156863)
    color: #ffffff

    &--title
      color: #fff
      font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif
      font-size: 21px
      font-weight: 400
      left: 8px
      line-height: 32px
      margin: 0
      max-width: 304px
      overflow: hidden
      position: absolute
      text-overflow: ellipsis
      top: 12px
      white-space: nowrap

      &-row
        height: 56px
        line-height: 56px
        position: relative

    &--close-icon
      position: absolute !important
      right: 4px
      top: 4px
      cursor: pointer
      background-color: transparent !important
      background-repeat: no-repeat !important
      background-size: 100% !important
      border: none !important

      &-font
        color: #ffffff !important


    &--search-box
      display: flex
      flex-direction: row
      align-items: center
      background-color: #fff
      border-radius: 2px
      position: relative
      width: 100%
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.258824), 0 2px 10px 0 rgba(0,0,0,0.156863)

      &-icon
        left: 14px
        top: 12px
        margin-right: 16px
        outline: none
        position: absolute !important
        z-index: 1 !important
        -webkit-transition: transform .15s cubic-bezier(0.4,0.0,1,1) !important
        -moz-transition: transform .15s cubic-bezier(0.4,0.0,1,1) !important
        -o-transition: transform .15s cubic-bezier(0.4,0.0,1,1) !important
        transition: transform .15s cubic-bezier(0.4,0.0,1,1) !important
        opacity: .3 !important

  &-body
    display: flex
    flex-direction: column
    width: 100%

    &--item
      width: 100%
      max-height: 48px
      overflow-y: hidden
      -webkit-transform: translateY(0)
      -ms-transform: translateY(0)
      transform: translateY(0)
      -webkit-transition: all 1s cubic-bezier(0.4,0.0,1,1) !important
      -moz-transition: all 1s cubic-bezier(0.4,0.0,1,1) !important
      -o-transition: all 1s cubic-bezier(0.4,0.0,1,1) !important
      transition: all 1s cubic-bezier(0.4,0.0,1,1) !important

      &-expanded
        @extend .faq-body--item
        max-height: 1000px
        overflow-y: visible

      &-answer
        margin-top: 8px
        padding-left: 16px
        padding-right: 16px
        font-family: $body-font
        line-height: normal
        text-align: justify
        color: #3c4043
        font-size: 14px
