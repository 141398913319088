.reset_password

  &_button
    margin-top: 20px
    width: 100%

  &_confirm
    font-size: 18px
    text-align: center
    color: #2b2e34
    margin-bottom: 20px