.anim-switch {
    animation: anim_switch 0.3s 1 linear;
    animation-fill-mode: forwards;
}

@keyframes anim_switch {
    from {
        margin-top: 0;
    }
    to {
        margin-top: 70px;
    }
}

@keyframes anim_switch_reverse {
    from {
        margin-top: 70px;
    }
    to {
        margin-top: 0;
    }
}

@media only screen {
    .anim-switch {
        margin-top: 0 !important;
        position: relative !important;
    }
}
