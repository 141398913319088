.modal
  position: fixed
  width: 100%
  height: 100%
  top: 0
  left: 0
  z-index: 1150

  &__bg
    width: 100%
    height: 100%
    position: absolute
    background-color: $modal-bg-color

  &__content
    padding: 20px
    background-color: $modal-content-color
    position: absolute
    top: 50%
    left: 50%
    // width: 640px
    width: calc(100% - 100px)
    // height: 530px
    box-sizing: border-box
    transform: translate(-50%, -50%)
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.6)
    border-radius: 4px

    &__add-user, &__add-raw-material, &__delete-product
      @extend .modal__content
      width: 510px

    &__manage_lines
      @extend .modal__content
      width: 1105px

    &__edit_run
      @extend .modal__content
      width: 770px

    &__add_run, &__trend-issue
      @extend .modal__content
      width: 770px

    &__move-run
      @extend .modal__content
      width: 728px

    &__add-line
      @extend .modal__content
      width: 1200px
      max-height: 90%
      overflow-y: scroll

    &__add_event
      @extend .modal__content
      width: 516px

    &__date_range_picker
      min-width: 640px
      width: 640px
      height: 440px

    &__confirm-run-change
      @extend .modal__content
      width: 880px

    &__reason_drill_down
      @extend .modal__content
      width: 856px
      max-height: 400px

    &__edit-product
      @extend .modal__content
      width: 1104px

    &-title
      width: 100%
      margin-bottom: 20px
      display: inline-block

      &__text
        font-size: 24px
        float: left
        font-family: $body-font

      &__close
        float: right
        cursor: pointer

        span
          font-size: 30px !important
    
    &-body
      width: 100%
      

    img
      width: 200px
      margin-left: 40px

    &__buttons
      margin-top: 20px
      height: 35px

.add-user, .delete-user
  &__text
    font-family: $body-font
    font-size: 15px
    line-height: 1.2em
    margin-bottom: 20px
    
  &__type
    display: inline-block
    width: 100%

    &-label
      @extend %default-label
      margin-bottom: 5px

  &__buttons
    margin-top: 24px
    float: right
  
  &__checkbox-text
    @extend %default-label
    margin: 15px 0px
  
  &__submit-error
    color: $brand-secondary
    margin-top: 5px
    @extend %default-label

.user-management

  &-confirmation

    &--body
      font-family: $body-font
      line-height: 1.2em
      margin-bottom: 20px
      margin-top: 20px
      color: #000

    &--sub-header
      font-size: 20px
      margin-bottom: 8px

    &--table
      margin-top: 8px

    &--list
      margin-left: 12px

.update-status

  &__error
    font-family: $body-font
    color: #F00
    margin-top: 8px
    padding-top: 8px
    display: table-cell
    padding-right: 10px

  &__success
    font-family: $body-font
    color: #26C109
    margin-top: 8px
    padding-top: 8px
    display: table-cell
    padding-right: 10px
    padding-left: 10px


.add-user__error
  @extend .update-status__error
  display: inline-flex

.date-picker

  &-dialog > div:nth-child(1) > div
    display: flex
    justify-content: center

  &-wrapper
    font-family: $body-font
    color: #000

    & div:focus, span:focus
      outline: 0

    & .DayPicker
      width: 100%

.selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)
  background-color: #f0f8ff !important
  color: #4a90e2 !important

.selectable .DayPicker-Day
  border-radius: 0 !important

.DayPicker-Caption
  text-align: center !important

.selectable .DayPicker-Day--start
  border-top-left-radius: 50% !important
  border-bottom-left-radius: 50% !important

.selectable .DayPicker-Day--end
  border-top-right-radius: 50% !important
  border-bottom-right-radius: 50% !important


.DayPicker-Months
  margin-top: 12px
  flex-wrap: nowrap !important
  overflow-y: auto

.DayPicker-NavBar
  width: calc(100% - 48px)
  margin-bottom: -52px
  margin-top: 12px
  padding: 0 24px
  display: flex
  justify-content: space-between

.navButton
  color: #000 !important
  margin: 0 !important
  border-width: 1px !important
  border-style: solid !important
  border-color: rgb(228, 231, 231) !important
  border-image: initial !important
  border-radius: 3px !important
  padding: 0 !important
  width: 32px !important
  height: 28px !important
  //font-size: 24px

  &:focus
    outline: 0


.drill-down

  &-table-header
    width: 800px
    height: 36px
    display: flex
    justify-content: flex-start
    align-items: center
    border-bottom: 1px solid #000


  &-row
    width: 800px
    height: 48px
    padding-top: 4px
    display: flex
    justify-content: flex-start
    align-items: center
    cursor: pointer
    border-bottom: 1px solid #e9e9e9

    &:hover
      background-color: #e9e9e9