.products
  width: 100%
  box-sizing: border-box
  background-color: $white-color
  padding: 20px
  font-family: $body-font
  margin-top: 20px
  min-height: 68vh

  &__title
    font-size: 24px
    margin-bottom: 35px

  &__subhead


  &__details
    display: flex
    flex-direction: column
    width: 100%
    justify-content: flex-start

    &__container
      display: flow-root

    &_wrapper
      width: calc(25% - 10px)
      float: left
      margin-left: 10px
      margin-top: 20px
      margin-bottom: 20px

  &__text
    float: left
    margin: 15px 0px 15px 10px

    &-title
      margin: 20px 0px 0px
      font-size: 24px
      font-family: $body-font

  &_buttons_wrapper
    display: flex
    justify-content: flex-end
    align-items: center
    overflow: hidden
    text-align: center

    &__updated-text
      color: #26C109
      font-size: 18px
      margin-right: 8px
      display: table-cell
      vertical-align: middle


  &__recipe-table
    clear: both
    margin-bottom: 30px
    width: 100%

  &__line-table
    display: flex
    clear: both
    margin-left: -10px
    margin-top: 20px

  &__manage-lines

    &__checkbox
      width: 50px
      float: left
      margin-right: 10px

    &__line-name
      width: 70px
      float: left
      margin-right: 10px

    &__text-field
      width: 256px
      float: left
      margin-right: 10px

    &__drop-down
      width: 50px
      float: left

.add-product

  &__fields
    display: flex
    clear: both
    margin-left: -10px
    margin-top: -50px


    &_wrapper
      width: 80%
      margin-top: -40px

.edit-product

  &__fields
    display: flex
    clear: both
    margin-left: -10px

.add-raw-material, .add-line-info

  &__fields
    display: flex
    flex-direction: column
    clear: both
    margin-bottom: 20px

    &_wrapper
      float: left
      margin: 20px

    &__item
      float: left
      margin-right: 10px


.bulk-edit
  &-fab-wrapper
    position: fixed
    right: 48px
    bottom: 24px
    z-index: 20

  &-fab-button
    background-color: $brand-secondary
    color: $white-color
    width: 100px
    height: 48px
    border-radius: 50px
    box-shadow: 4px 3px 8px rgba(0, 0, 0, 0.3)
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    box-sizing: border-box

    span
      color: $white-color !important
      font-size: 20px !important

    &:hover
      background-color: $white-color

      span, .bulk-edit-fab-button-text
        color: $brand-secondary !important

    &-text
      color: $white-color
      font-size: 20px
      margin-left: 4px

.products_table_head_cell
  font-size: 14px
  font-weight: 500 !important
  color: rgba(0, 0, 0, 1) !important
  width: 20%
    
.products_table_filter_cell
  &:hover
    div.table_filter_icon_cell
      opacity: 0.5

.products_table_body_cell
  font-size: 14px
  font-weight: 400 !important
  color: rgba(0, 0, 0, 0.87) !important
  width: 20%

.table_filter_icon_cell
  opacity: 0
  transition: opacity 0.2s ease-in
  

.table_filter_icon_cell_selected
  opacity: 1

.snackbar .MuiSnackbarContent-root
  padding: 8px 24px
  min-width: 256px