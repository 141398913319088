body
  font-family: $body-font
  background-color: $body-bg-color

.body
  background-color: $body-bg-color
  font-family: $body-font

.container
  font-family: $body-font

%header-base
  width: calc(100%)
  padding-left: 140px
  position: absolute
  right: 0

%header-base--collapse
  width: calc(100%)

%card-base
  //width: calc(100% - 40px)
  width: calc(100% - 236px)
  margin: 20px 20px 20px 196px
  box-sizing: border-box
  background-color: $white-color
  padding: 20px
  font-family: $body-font
  box-shadow: 0px 13px 15px rgba(0,0,0,0.3)
  transform: translateX(0)
  transition: all 195ms ease-in-out

%card-base--collapse
  //width: calc(100% - 40px)
  width: calc(100% - 96px)
  //margin: 20px
  margin: 20px 20px 20px 56px
  box-sizing: border-box
  background-color: $white-color
  padding: 20px
  font-family: $body-font
  box-shadow: 0px 13px 15px rgba(0,0,0,0.3)
  transform: translateX(0)
  transition: all 225ms ease-in-out

.main_content_wrapper
  height: calc(100vh - 70px)
  overflow: auto
  margin-top: 70px
  display: flex
  flex-direction: column
  margin-left: 64px
  justify-content: space-between
  font-family: $body-font

.flex-box

  &__row
    display: flex
    flex-direction: row
    align-items: center
    height: 100%
    width: 100%
    font-family: $body-font

    &--double
      @extend .flex-box__row
      height: 50%


  &__col
    display: flex
    flex-direction: column
    align-items: center
    font-family: $body-font

.justify-start
  justify-content: flex-start

.justify-center
  justify-content: center

.justify-end
  justify-content: flex-end

.justify-space-between
  justify-content: space-between

.justify-space-around
  justify-content: space-around

.align-start
  align-items: flex-start

.align-center
  align-items: center

.align-baseline
  align-items: baseline

.align-end
  align-items: flex-end

.font-size
  &-mice
    font-size: 8px !important

  &-sm
    font-size: 12px !important

  &-md
    font-size: 16px !important

  &-bg
    font-size: 20px !important

  &-xbg
    font-size: 24px !important

  &-sh
    font-size: 28px !important

  &-hd
    font-size: 32px !important

  &-xl
    font-size: 36px !important

  &-four-up-xxl
    font-size: 64px !important

  &-xxl
    font-size: 72px !important

  &-xxxl
    font-size: 80px !important

  &-120
    font-size: 120px !important

  &-presentation
    font-size: 160px


.margin-bottom

  &-sm
    margin-bottom: 4px

  &-bg
    margin-bottom: 8px

  &-xl
    margin-bottom: 16px

.margin-right
  &-sm
    margin-right: 4px

  &-bg
    margin-right: 8px

  &-xl
    margin-right: 16px

.left-align
  text-align: left

.right-align
  text-align: right

.floating-action-button

  &__wrapper
    position: absolute
    display: flex
    justify-content: flex-end
    margin-top: -28px
    right: 16px
    z-index: 1

.header--collapse
  @extend %header-base--collapse
.header
  @extend %header-base

.card--collapse
  @extend %card-base--collapse
.card
  @extend %card-base

  &__flex-box
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    height: 360px



#react-paginate ul
  display: inline-block
  padding-left: 15px
  padding-right: 15px


#react-paginate li
  display: inline-block

.no-data
  font-size: 20px !important
  text-anchor: middle !important


.bold
  font-weight: bold !important

.reference-line tspan
  font-size: 8px !important

.brush-class
  margin-top: 50px
  margin-bottom: 50px

  &> :first-child
    stroke-width: 0.1

  & > .recharts-brush-texts
    display: none

.label
  fill: #000
  font-size: 12px
  text-anchor: middle

.bg-text
  //fill: none
  text-anchor: middle
  font-size: 12px
  stroke-width: 6px
  stroke-linejoin: round
  stroke-linecap: butt

