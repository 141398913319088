.liveview
  @extend %card-base
  padding: 30px

  &-add-run

    &__dialog-content
      width: 560px

  &__header
    background-color: #e9e9e9
    margin: -20px -20px 20px -20px
    padding: 20px 20px 0px 20px

.liveview--collapse
  @extend %card-base--collapse
  padding: 30px

.lines
  width: 100%
  display: inline-block
  font-size: 16px

  &--dropdown
    overflow: visible !important
    height: inherit !important
    margin-top: 16px !important
    line-height: 56px !important

    >div
      display: flex
      justify-content: space-between
      align-items: center

.factories
  width: 100%
  display: inline-block
  font-size: 16px

  &--dropdown
    overflow: visible !important
    height: inherit !important
    z-index: 0 !important

    >div
      display: flex
      justify-content: space-between
      align-items: center

    &-header
      font-size: 1rem
      font-family: "Roboto", "Helvetica", "Arial", sans-serif
      font-weight: 400
      line-height: 1.5
      letter-spacing: 0.00938em

    &--title
      display: flex
      min-height: 48px
      align-items: center
      background: #0A71CE
      color: #fff

      &_padding-left
        @extend .factories--dropdown--title
        padding-left: 12px

    &--timezone
      display: flex
      min-height: 48px
      align-items: center
      background: white
      color: rgba(0, 0, 0, 0.87)

    &-wrapper
      display: flex
      flex-direction: column
      width: 100%

    &--icon-button
      padding: 6px !important

    &--menu
      padding: 0 !important

    &--list-item-icon
      min-width: initial !important

    &--accordion
      margin-bottom: 0px

    &--menu-item
      min-height: 36px !important
      margin-bottom: 8px !important
      margin-left: 0px !important
      padding-left: 16px !important
      display: flex !important
      align-items: center !important
      justify-content: space-between !important

      &:hover
        background: rgba(33, 150, 243, 0.04) !important

      &:hover .chevron-right
        opacity: 1

      &:focus
        background: #fff !important
        color: #004485 !important


.chevron-right
  opacity: 0

.presentation
  font-size: 19.2px

  &_title-column
    width: 100%
    display: flex
    flex-direction: column

  &_col
    width: 50%
    height: 100%
    display: flex
    flex-direction: column

    &--3
      @extend .presentation_col
      width: 33.3%

  &_row
    height: 100%
    width: 100%
    justify-content: space-around
    display: flex
    flex-direction: row

  &_height
    height: 100%
    height: -webkit-fill-available
    height: -moz-available
    max-height: 100%
    max-height: -webkit-fill-available
    max-height: -moz-available


.last_updated_stamp
  display: flex
  font-size: 10px
  color: #bfbfbf
  width: 100%
  justify-content: flex-end

.line-item__giveaway
  margin-bottom: 20px


.line-item__column
  width: 100%
  display: inline-block

  &-item
    width: calc(100% - 10px)
    margin-right: 10px
    margin-bottom: 20px
    float: left
    background-color: $line-item-bg-color
    border-radius: 4px
    overflow: hidden
    min-height: 300px
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.3)
    display: flex

    &:nth-of-type(5n+1)
      margin-left: 0

    &__line-name
      text-align: left
      font-size: 125%
      margin-bottom: 8px
      text-transform: uppercase

    &__speed-actual
      font-size: 150%
      font-weight: bold

      &-presentation
        margin-bottom: 8px
        font-weight: bold

        &--normal
          margin-bottom: 8px
          font-weight: normal

    &__product-name
      font-size: 87.5%
      font-weight: bold
      margin-bottom: 5px

    &__product-description
      font-size: 87.5%
      font-weight: bold
      margin-bottom: 15px

    &__speed
      width: 100%
      display: inline-block
      margin-bottom: 15px
      &:after
        clear: both

      &-low-margin
        margin-bottom: 5px
        &:after
          clear: both

    &__speed-label, &__status-label
      float: left
      width: 50%
      text-transform: uppercase
      font-weight: bold
      font-size: 75%
      margin-bottom: 5px

    &__speed-unit
      float: left
      width: 50%
      text-transform: uppercase
      font-size: 75%
      margin-bottom: 5px

    &__speed-info, &__status-description
      float: left
      width: 50%
      font-size: 75%

    &__status-label, &__status-description
      width: 100%


  &-content
    margin-top: 45px
    box-sizing: border-box
    float: left
    width: 49%

    &--presentation
      @extend .line-item__column-content
      width: 100%
      padding-left: 24px
      padding-right: 24px

    &--presentation-4
      @extend .line-item__column-content
      width: 100%
      padding-left: 24px
      padding-right: 24px
      margin-top: 8px

    &__status
      font-weight: normal
      padding: 4px 8px
      border-radius: 2px
      margin-left: 4px

      &--in_range
        @extend .line-item__column-content__status
        background-color: $line-online

      &--below
        @extend .line-item__column-content__status
        background-color: $line-warning

      &--above
        @extend .line-item__column-content__status
        background-color: $line-warning

      &--no_giveaway
        @extend .line-item__column-content__status
        background-color: $line-offline

      &--no_targets
        @extend .line-item__column-content__status
        background-color: $line-offline




    &__title
      text-transform: uppercase
      font-weight: bold
      font-size: 81.3%
      margin-bottom: 15px
      margin-top: 15px

      &:first-of-type
        margin-top: 0px

    &_graph-text
      text-align: center
      padding-top: 15px

    &__item
      display: inline-block
      width: 100%

      &-title
        font-size: 75%
        text-transform: uppercase
        width: 50%
        float: left

      &-value
        font-size: 75%
        width: 50%
        float: left



  &-header
    border-radius: 4px
    padding: 15px
    box-sizing: border-box
    display: inline-block
    width: 100%
    min-height: 150px
    cursor: pointer

    &_wrapper
      float: left
      width: 100%
      height: 100%

    &--presentation
      @extend .line-item__column-header_wrapper
      width: 100%
      min-height: 416px

    &--presentation-4
      float: left
      width: 100%
      min-height: 200px

    &--presentation-8
      float: left
      width: 100%
      height: 100%


    &--down
      @extend .line-item__column-header
      background-color: $line-error
      color: $white-color
      height: 100%

    &--slow
      @extend .line-item__column-header
      background-color: $line-warning
      color: black
      height: 100%

    &--online
      @extend .line-item__column-header
      background-color: $line-online
      color: $white-color
      height: 100%

    &--offline
      @extend .line-item__column-header
      background-color: $line-offline
      color: $black-color !important
      height: 100%
      cursor: not-allowed

.scoreboard

  &--header
    padding: 15px
    box-sizing: border-box
    display: inline-block
    width: 100%
    min-height: 150px
    &--down
      @extend .scoreboard--header
      background-color: $line-error
      color: $white-color
      height: 100%

    &--slow
      @extend .scoreboard--header
      background-color: $line-warning
      color: black
      height: 100%

    &--online
      @extend .scoreboard--header
      background-color: $line-online
      color: $white-color
      height: 100%

    &--offline
      @extend .scoreboard--header
      background-color: $line-offline
      color: $black-color !important
      height: 100%

.line-item__grid
  width: 100%
  display: inline-block

  &-item
    margin-bottom: 20px
    width: calc(50% - 10px)
    margin-left: 0px
    float: left
    background-color: $line-item-bg-color
    border-radius: 4px
    overflow: hidden

    &:nth-of-type(2n+1)
      margin-right: 20px

    &__line-name
      text-align: center
      font-size: 20px
      margin-bottom: 8px
      text-transform: uppercase

    &__product-name
      font-size: 14px
      font-weight: bold
      margin-bottom: 5px

    &__product-description
      font-size: 14px
      font-weight: bold
      margin-bottom: 15px

    &__speed
      width: 100%
      display: inline-block
      margin-bottom: 15px
      &:after
        clear: both

    &__speed-label, &__status-label
      float: left
      width: 50%
      text-transform: uppercase
      font-weight: bold
      font-size: 12px
      margin-bottom: 5px

    &__speed-info, &__status-description
      float: left
      width: 50%
      font-size: 12px

    &__status-label, &__status-description
      width: 100%

      &__chrono
        font-size: 1.875rem


  &-content
    padding: 15px
    margin-top: 15px
    box-shadow: border-box

    &__title
      text-transform: uppercase
      font-weight: bold
      font-size: 81.3%
      margin-bottom: 15px
      margin-top: 15px

      &:first-of-type
        margin-top: 0px

    &__item
      display: inline-block
      width: 100%

      &-title
        font-size: 75%
        text-transform: uppercase
        width: 50%
        float: left

      &-status
        font-size: 75%
        text-transform: uppercase
        float: left
        padding-bottom: 2%

      &-value
        font-size: 75%
        width: 50%
        float: left

  &-header
    border-radius: 4px
    padding: 15px
    box-shadow: border-box
    display: inline-block
    width: 100%
    min-height: 150px
    cursor: pointer

    &--down
      @extend .line-item__grid-header
      color: $white-color
      background-color: $line-error

    &--slow
      @extend .line-item__grid-header
      background-color: $line-warning

    &--online
      @extend .line-item__grid-header
      background-color: $line-online
      color: $white-color

    &--offline
      @extend .line-item__grid-header
      background-color: $line-offline
      cursor: not-allowed



.line-status
  width: 100%
  display: inline-block

  &:after
    clear: both

  &-item
    width: calc(25% - 12px)
    margin-left: 15px
    border-radius: 4px
    padding: 4px
    float: left
    line-height: 4em
    box-sizing: border-box
    background-color: $white-color

    &:after
      clear: both

    &:first-of-type
      margin-left: 0

    &--down
      @extend .line-status-item

      .line-status-item__number
        background-color: $line-error

    &--slow
      @extend .line-status-item

      .line-status-item__number
        background-color: $line-warning

    &--online
      @extend .line-status-item

      .line-status-item__number
        background-color: $line-online

    &--offline
      @extend .line-status-item

      .line-status-item__number
        background-color: $line-offline


    &__name
      font-family: $body-font
      font-size: 28px
      float: left
      width: calc(75% - 20px)
      background-color: $white-color
      padding-left: 10px
      box-sizing: border-box

    &__number
      font-family: $body-font
      font-size: 50px
      text-align: center
      width: calc(25% + 20px)
      float: left
      color: $white-color
      border-radius: 4px


.line-view-options
  width: 75%
  margin: 20px 0px -3px 0px
  text-align: center
  display: inline-block
  overflow: hidden
  &--tablet
    width: 75%
    margin: 2px 0px -3px 0px
    text-align: center
    display: inline-block
    overflow: hidden

  &__item
    float: left
    text-transform: uppercase
    background-color: $options-bg-color
    color: $black-color
    box-sizing: border-box
    text-align: center
    font-size: 16px
    font-weight: bold
    line-height: 2.5em
    cursor: pointer
    padding: 4px 50px
    &--tablet
      @extend .line-view-options__item
      font-size: 14px



    &:hover
      background-color: $options-bg-color-selected
      border-bottom: 2px solid $brand-tertiary

    &:last-of-type
      border-right: none

    &--selected
      @extend .line-view-options__item
      background-color: $options-bg-color-selected
      cursor: default
      border-bottom: 2px solid $brand-tertiary

.liveview-detail
  @extend %card-base

  &__efficiency
    margin-bottom: 20px

    &-title
      @extend %default-title
      margin-bottom: 10px

  &__eventlog

    &-title
      @extend %default-title
      margin-bottom: 10px

.liveview--modal
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: hidden
  background-color: $liveview-modal-bg
  font-family: $body-font
  z-index: 1201

.center
  text-align: center

.scoreboard__chart
  height: 70%

  .recharts-text
    font-size: 28px !important
    font-family: $body-font, Arial, Helvetica, sans-serif

.scoreboard__chart-full
  height: 85%

  .recharts-text
    font-size: 16px !important
    font-family: $body-font, Arial, Helvetica, sans-serif

.legend-item
  display: flex
  flex-flow: row
  flex-wrap: wrap
  margin-right: 8px

.legend-icon
  height: 16px
  width: 24px
  margin-right: 4px
  margin-bottom: 4px


.blink
  animation: blinker 8s cubic-bezier(0.6, -0.28, 0.74, 0.05) infinite

@keyframes blinker
  0%, 45%
    opacity: 0
  45%, 65%
    opacity: 100%
  65%, 100%
    opacity: 0
