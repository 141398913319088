.settings
  @extend %card-base

  &__section-title
    font-size: 18px
    color: #000
    font-weight: 500
    margin-bottom: 12px
    line-height: 1.2

  &__hr
    border-right-style: initial
    border-bottom-style: initial
    border-left-style: initial
    border-right-color: initial
    border-bottom-color: initial
    border-left-color: initial
    margin: 26px -20px
    border-width: 2px 0 0
    border-image: initial
    border-top: 2px solid rgb(235, 236, 240)

  &__subhead
    background-color: #e9e9e9
    padding: 20px 20px 20px 20px
    margin: -20px -20px 0 -20px

  &__spacer
    display: block
    width: 100%
    height: 20px

  &__table
    margin: 10px 10px 56px 10px
    position: relative

  &-options
    width: 80%
    margin: 20px 0 -3px 0
    padding-left: 20px
    text-align: center
    display: inline-block
    overflow: hidden
    &--tablet
      width: 75%
      margin: 2px 0px -3px 0px
      text-align: center
      display: inline-block
      overflow: hidden

    &__item
      float: left
      width: 16%
      text-transform: uppercase
      text-decoration: none
      background-color: $options-bg-color
      color: $black-color
      box-sizing: border-box
      text-align: center
      font-size: 16px
      font-weight: bold
      line-height: 2.5em
      cursor: pointer
      padding: 4px 5px
      &--tablet
        @extend .settings-options__item
        font-size: 14px

        &--selected
          @extend .settings-options__item--tablet
          background-color: $options-bg-color-selected
          cursor: default
          border-bottom: 2px solid $brand-tertiary

      &:hover
        background-color: $options-bg-color-selected
        border-bottom: 2px solid $brand-tertiary

      &:last-of-type
        border-right: none

      &--selected
        @extend .settings-options__item
        background-color: $options-bg-color-selected
        cursor: default
        border-bottom: 2px solid $brand-tertiary

  &__body
    background-color: white
    margin: -20px -20px 0px -20px

  &__line-details
    &--info-table
      width: calc(50% - 10px)
      margin-left: 10px
      float: left

    &--text-field
      padding-left: 16px
      display: inherit !important

    &--list-item
      padding-left: 16px

  &__reason-details
    &--info-table
      width: calc(30% - 10px)
      margin-left: 10px
      padding-right: 20px
      float: left

    &--add-info-table
      width: calc(45% - 10px)
      margin-left: 10px
      padding-right: 20px
      float: left

    &--text-field
      margin-right: 60px !important
      width: 260px

    &--list-item
      padding-left: 16px


.drag
  font-size: 24px
  &:hover
    cursor: move

.downtime-export
  display: flex
  justify-content: center
  height: 300px
  padding-top: 200px

  &-fields
    width: 100%
    box-sizing: border-box
    background-color: $white-color
    padding-left: 20px
    padding-right: 20px
    font-family: $body-font
    margin-top: 20px


.settings_fab
  position: absolute !important
  right: 60px
  top: 52px
  z-index: 100

.settings_units_fab
  position: absolute !important
  right: 0
  top: 0
  margin-top: -56px !important
  z-index: 100

.settings-line-table-button
  margin-left: 8px !important

.table-head-elevation
  box-shadow: none

.settings-reason-sub-title
  margin-bottom: 20px !important


.settings-reasons_card
  margin: 20px 0
  box-sizing: border-box
  background: #fff
  box-shadow: 0 0 8px rgba(0,0,0,0.12)
  display: flex
  flex-direction: column
  padding: 30px 30px 60px

  &--title
    margin-bottom: 20px !important

  &--section
    margin: 30px 0

  &--save-button
    display: flex
    justify-content: flex-end

.settings-reasons_event_table_head_cell
  font-size: 14px
  color: rgba (0, 0, 0, 1) !important
  width: 16.6%

.settings-reasons_event_table_body_cell
  font-size: 16px
  color: rgba(0, 0, 0, 0.87) !important
  width: 16.6%
