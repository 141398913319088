.users
  width: 100%
  box-sizing: border-box
  background-color: $white-color
  padding: 20px
  font-family: $body-font
  margin-top: 20px

  &__subhead
    margin-top: 50px

  &__spacer
    display: block
    width: 100%
    height: 20px

  &__role
    cursor: pointer
    text-decoration: none

    &:hover
      color: $brand-primary
      cursor: pointer

  &__wrapper
    margin-top: -70px

.users-view-options
  width: 50%
  display: flex
  justify-content: center
  text-align: center
  overflow: hidden
  margin: 20px auto -3px
  padding-top: 20px
  padding-bottom: 20px

  &--tablet
    width: 25%
    text-align: center
    overflow: hidden
    margin: 2px auto -3px

  &__item
    float: left
    text-transform: uppercase
    background-color: white
    color: $black-color
    box-sizing: border-box
    text-align: center
    font-size: 16px
    font-weight: bold
    line-height: 2.5em
    cursor: pointer
    padding: 4px 50px
    &--tablet
      @extend .users-view-options__item
      font-size: 14px

    &:hover
      background-color: $options-bg-color-selected
      border-bottom: 2px solid $brand-tertiary

    &:last-of-type
      border-right: none

    &--selected
      @extend .users-view-options__item
      background-color: white
      cursor: default
      border-bottom: 2px solid $brand-tertiary


.user-fab
  position: absolute !important
  right: 60px
  top: 120px

.user-management

  &__subhead
    background-color: #e9e9e9
    padding: 20px 20px 0 20px
    margin: -20px -20px 20px -20px
    display: flex
    flex-direction: column

    &-selectors
      margin-top: 10px
      display: flex
      flex-direction: row

    &-text
      display: flex
      flex-direction: column
      font-size: 24px
      padding-bottom: 16px
      min-width: 100px

    &-arrow
      font-size: 24px
      padding-bottom: 16px
      padding-right: 24px
      padding-left: 24px
      margin-top: 24px

  &__dialog

    &-div
      margin-bottom: 16px

    &-text-field
      width: 256px
      margin-right: 24px

    &-select
      width: 200px
      margin-right: 24px

  &--dropdown
    overflow: visible !important
    height: inherit !important
    z-index: 0 !important

    >div
      display: flex
      justify-content: space-between
      align-items: center

    &--title
      font-size: 14px


.users_table_head_cell
  font-size: 14px
  font-weight: 500 !important
  color: rgba(0, 0, 0, 1) !important
  width: 20%

.users_table_body_cell
  font-size: 14px
  font-weight: 400 !important
  color: rgba(0, 0, 0, 0.87) !important