.login
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)


  div
    &:focus
      outline: 0

  img
    width: 200px
    margin-left: 40px

  &__buttons
    margin-top: 20px
  
  &--error
    font-size: 10px
    color: $brand-secondary
    margin-top: 10px

  &__help
    font-size: 12px
    margin-top: 12px